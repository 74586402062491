// extracted by mini-css-extract-plugin
export var column = "LocalityShowCities__column__v_4wr";
export var flex = "LocalityShowCities__flex__DIMtR";
export var flexColumn = "LocalityShowCities__flexColumn__P34BX";
export var gap1 = "LocalityShowCities__gap1__PsyXz";
export var gap2 = "LocalityShowCities__gap2__cT0jN";
export var gap3 = "LocalityShowCities__gap3__R_new";
export var gap4 = "LocalityShowCities__gap4__FmYa4";
export var gap5 = "LocalityShowCities__gap5__TKukb";
export var localitiesContainer = "LocalityShowCities__localitiesContainer__rw2EM";
export var localitiesList = "LocalityShowCities__localitiesList__8yv1H";
export var providersList = "LocalityShowCities__providersList__Meb5k";
export var row = "LocalityShowCities__row__sVowP";