// extracted by mini-css-extract-plugin
export var column = "LocalityShowStates__column__aTzJb";
export var flex = "LocalityShowStates__flex__eP_gn";
export var flexColumn = "LocalityShowStates__flexColumn__BItsw";
export var gap1 = "LocalityShowStates__gap1__nqF0g";
export var gap2 = "LocalityShowStates__gap2__IPFSG";
export var gap3 = "LocalityShowStates__gap3__V9p9j";
export var gap4 = "LocalityShowStates__gap4__N6Txo";
export var gap5 = "LocalityShowStates__gap5__FxpEc";
export var localitiesContainer = "LocalityShowStates__localitiesContainer__xU7TD";
export var localitiesList = "LocalityShowStates__localitiesList__OCcik";
export var providersList = "LocalityShowStates__providersList__ZcqOi";
export var row = "LocalityShowStates__row__TBJZS";