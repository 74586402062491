// extracted by mini-css-extract-plugin
export var column = "LocalityShowProviders__column__CasQI";
export var flex = "LocalityShowProviders__flex__Erkp5";
export var flexColumn = "LocalityShowProviders__flexColumn__qLcp0";
export var gap1 = "LocalityShowProviders__gap1__QBNQY";
export var gap2 = "LocalityShowProviders__gap2__t3I16";
export var gap3 = "LocalityShowProviders__gap3__B6lkq";
export var gap4 = "LocalityShowProviders__gap4___P87D";
export var gap5 = "LocalityShowProviders__gap5__NpjCt";
export var localitiesContainer = "LocalityShowProviders__localitiesContainer__OnZIz";
export var providersList = "LocalityShowProviders__providersList__nJRdi";
export var row = "LocalityShowProviders__row__Bk8eT";